<template>
  <div>
    <ui-component-drawer
      v-if="showSideDrawer"
      :drawerTitle="drawerTitle"
      :showDrawer="showSideDrawer"
      :onClickClose="hideDrawer"
      position="right"
    >
      <template v-slot:content>
        <OptionSummary v-if="showOptionSummary" :optionId="itemId" />
      </template>
    </ui-component-drawer>

    <ModalCreateOption
      v-if="showModalCreateOption"
      :showModal="showModalCreateOption"
      :locationId="locationId"
      :onClickCancel="hideModal"
      @dataSaved="optionCreated"
    />

    <ModalDeleteOption
      v-if="showModalDeleteOption"
      :showModal="showModalDeleteOption"
      :locationId="locationId"
      :optionId="selectedOption.Id"
      :onClickCancel="hideModal"
      @dataSaved="optionCreated"
    />

    <!-- <section class="section is-small">
      <div class="container">
        <ImportOptions />
      </div>
    </section> -->

    <div class="">
      <div class="actionBar has-padding has-margin-bottom">
        <div class="level">
          <div class="level-left"></div>
          <div class="level-right">
            <a @click="setShowCreateOption">
              <span class="icon">
                <font-awesome-icon :icon="['fas', 'plus']" />
              </span>
              <span>Create option</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="navbar-menu">
      <div class="navbar-start">
        <select v-model="optionsPage.selectedCategory">
          <option :value="''" :selected="optionsPage.selectedCategory === ''"
            >All categories</option
          >
          <option
            v-if="locationId > 0"
            :value="'FB'"
            :selected="optionsPage.selectedCategory === 'FB'"
            >F&amp;B</option
          >
          <option
            v-if="locationId > 0"
            :value="'AV'"
            :selected="optionsPage.selectedCategory === 'AV'"
            >AV</option
          >
          <option
            v-if="locationId > 0"
            :value="'Parking'"
            :selected="optionsPage.selectedCategory === 'Parking'"
            >Parking</option
          >
          <option
            v-if="locationId > 0"
            :value="'Other'"
            :selected="optionsPage.selectedCategory === 'Other'"
            >Other</option
          >
        </select>
      </div>

      <div class="navbar-end">
        <div class="field has-addons">
          <input
            type="text"
            v-model="optionsPage.searchTerm"
            v-on:keyup.13="searchOptions"
            class="input is-small"
            placeholder="Search options"
          />
          <button
            class="button is-primary is-small"
            :class="{ 'is-loading': isBusy }"
            @click="searchOptions"
          >
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
    </div>

    <div>
      <ui-loader v-if="isBusy" />

      <Message
        v-if="!isBusy && options.length === 0"
        :message="'No options created'"
      />

      <table
        v-if="options.length > 0"
        class="table is-fullwidth is-striped is-hoverable"
      >
        <thead>
          <tr>
            <th>Name</th>
            <th>Category</th>
            <th class="has-text-right">Origin</th>
            <th width="100">&nbsp;</th>
          </tr>
        </thead>
        <transition-group
          name="staggered-fade"
          v-bind:css="false"
          v-on:before-enter="beforeEnter"
          v-on:enter="enter"
          tag="tbody"
        >
          <tr
            v-for="(option, index) in filteredOptions"
            :key="'tr_' + index"
            :data-index="index"
          >
            <td>
              <a @click="goToOption(option.Id)">{{ option.Name }}</a>
            </td>
            <td>
              <span>{{ option.CategoryName }}</span>
            </td>
            <td class="has-text-right">
              {{ option.SupplierType }}
            </td>
            <td class="has-text-right" width="75">
              <div class="field is-grouped has-text-right">
                <p class="control">
                  <a @click="setShowOptionSummary(option.Id)">
                    <span class="icon">
                      <font-awesome-icon :icon="['fas', 'info-circle']" />
                    </span>
                  </a>
                </p>

                <p class="control">
                  <a @click="goToOption(option.Id)">
                    <span class="icon">
                      <font-awesome-icon :icon="['fas', 'edit']" />
                    </span>
                  </a>
                </p>

                <p class="control">
                  <a
                    v-if="option.SupplierType !== 'Channel' || isChannelOptions"
                    @click="setShowDeleteOption(option)"
                  >
                    <span class="icon has-text-danger">
                      <font-awesome-icon :icon="['fas', 'trash-alt']" />
                    </span>
                  </a>
                </p>
              </div>
            </td>
          </tr>
        </transition-group>
      </table>
    </div>
  </div>
</template>

<script>
import optionProvider from '@/providers/option'
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import staggeredAnimation from '@/mixins/staggeredAnimation'

export default {
  components: {
    Message: () => import('@/components/UI/Message'),
    ModalCreateOption: () => import('@/components/Options/ModalCreateOption'),
    ModalDeleteOption: () => import('@/components/Options/ModalDeleteOption'),
    OptionSummary: () => import('@/components/Options/OptionSummary'),
    //SearchBar: () => import('@/components/UI/Components/SearchBar'),
    //ImportOptions: () => import('@/components/Options/ImportOptions'),
  },

  mixins: [staggeredAnimation],

  props: {
    locationId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      drawerTitle: '',
      hasLoaded: false,
      isSearching: false,
      itemId: 0,
      meetingtypeId: 0,
      selectedOption: null,
      showModal: false,
      showModalCreateOption: false,
      showModalDeleteOption: false,
      showOptionSummary: false,
      showSideDrawer: false,
    }
  },

  computed: {
    ...mapGetters('optionStore', ['isBusy']),
    ...mapState('channelStore', ['channel']),
    ...mapState('optionStore', ['optionsPage', 'options']),

    isChannelOptions() {
      return this.locationId === 0
    },

    filteredOptions() {
      let options = this.options
      if (
        this.locationId > 0 &&
        this.options &&
        this.optionsPage.selectedCategory !== '' &&
        this.options.length > 0
      ) {
        options = this.options
        options = options.filter(
          (o) => o.Location.CategoryId === this.optionsPage.selectedCategory
        )
      }

      return options
    },
  },

  created() {
    if (!this.isBusy && this.options.length === 0) {
      this.searchOptions()
    }
  },

  methods: {
    ...mapMutations('optionStore', ['setOptionsPage', 'setOptions']),
    ...mapActions('optionStore', ['getLocationOptions']),

    searchOptions() {
      this.optionsPage.locationId = this.locationId
      this.optionsPage.channelId = this.locationId > 0 ? 0 : this.channel.Id
      //this.optionsPage.searchTerm = searchTerm

      this.setOptionsPage(this.optionsPage)

      if (this.locationId > 0) {
        this.getLocationOptions()
      } else {
        this.getChannelOptions()
      }
    },

    setSelectedCategory(val) {
      this.optionsPage.selectedCategory = val
    },

    getLocationOptions() {
      this.isLoading = true
      optionProvider.methods
        .getLocationOptions(this.locationId, 0, this.optionsPage.searchTerm)
        .then((response) => {
          this.setOptions(response.data.Results)
        })
        .catch((e) => {})
        .finally(() => {
          this.isLoading = false
        })
    },

    getChannelOptions() {
      this.isLoading = true
      optionProvider.methods
        .getChannelOptions(this.channel.Id, 0, this.optionsPage.searchTerm)
        .then((response) => {
          this.setOptions(response.data.Results)
        })
        .catch((e) => {})
        .finally(() => {
          this.isLoading = false
        })
    },

    getTotalNrOfOptions(categoryId) {
      let output = ' ({nr})'
      // if (this.options && this.options.length > 0) {
      //   return output.replace(
      //     '{nr}',
      //     categoryId
      //       ? this.options.filter((o) => o.Location.CategoryId === categoryId)
      //           .length
      //       : this.options.length
      //   )
      // }
      return ''
    },

    optionCreated() {
      let self = this

      self.searchOptions('')

      EventBus.$emit('showToast', {
        type: 'info',
        message: 'New option created!',
      })
    },

    setShowDeleteOption(option) {
      this.selectedOption = option
      this.showModalDeleteOption = true
    },

    setShowCreateOption() {
      this.showModal = true
      this.showModalCreateOption = true

      this.modalTitle = 'Create new option'
    },

    hideModal() {
      this.showModal = false
      this.showModalCreateOption = false
      this.showModalDeleteOption = false

      this.modalTitle = ''
      this.selectedOption = null
    },

    setShowOptionSummary(optionId) {
      this.itemId = optionId
      this.drawerTitle = 'Option detail'
      this.showSideDrawer = true
      this.showOptionSummary = true
    },

    hideDrawer() {
      this.itemId = 0
      this.drawerTitle = ''
      this.showSideDrawer = false
      this.showOptionSummary = false
    },

    goToOption(optionId) {
      if (this.locationId > 0) {
        this.$router.push({
          name: `locations-options-detail`,
          params: { optionId: optionId },
        })
      } else {
        this.$router.push({
          name: `options-detail`,
          params: { optionId: optionId },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/template.scss';
.ui-drawer-component {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;

  .drawer {
    display: flex;
    flex-direction: column;
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    top: 0; /* Stay at the top */
    left: auto;
    right: auto;
    background-color: white;
    height: 100vh;
    max-width: 420px;
    transition: 0.75s;
    animation-duration: 0.5s;
    @include box-shadow();

    &.right {
      right: 0;
      width: 100%;
    }

    &-minimal {
      width: auto;
    }

    .drawer-head {
      align-items: center;
      background-color: #eeeeee;
      display: flex;
      flex-shrink: 0;
      justify-content: flex-start;
      padding: 15px;
      position: relative;

      .drawer-title {
        flex-grow: 1;
        flex-shrink: 0;
        font-size: $size-4;
      }
    }

    .drawer-body {
      overflow: auto;
    }
  }
}
</style>
